import { BasePageContent, LoadingSpinner, PageNotFound, PageWrap } from "components/common";
import { OfferAccepted, UploadImage } from "components/image-components";
import { verticalLanguageSettings } from "../const";
import { OFFER_ACCEPTED_SUCCESS } from "./const";
import { useThirdPartyAssignmentReceiveMutation } from "types/graphql.types";
import { useEffect, useState } from "react";

interface IProps {
  caseId: string;
}

const ReceiveAssignment: React.FC<IProps> = ({ caseId }) => {
  const [count, setCount] = useState(0);
  const variables = {
    input: {
      configName: "gdpr-claim-purchasing",
      input: {
        case: {
          id: caseId,
        },
      },
    },
  };

  const [
    thirdPartyAssignmentReceiveMutation,
    { data: dataAssignment, loading: loadingAssignment, error: errorAssignment },
  ] = useThirdPartyAssignmentReceiveMutation();

  useEffect(() => {
    receive();
  });

  const receive = () => {
    if (count === 0) {
      setCount(count + 1);

      thirdPartyAssignmentReceiveMutation({
        variables,
      }).then((res) => {
        if (res?.errors) {
          res.errors.forEach(({ message }) => {
            console.log("m:", message);
            return <PageNotFound error="Something went wrong." />;
          });
          return;
        }
      });
    }
  };

  if (loadingAssignment) {
    return <LoadingSpinner />;
  }

  if (errorAssignment && errorAssignment.graphQLErrors[0]?.extensions?.code === "400") {
    return <PageNotFound error="Something went wrong." />;
  }

  const getFinishImage = () => <OfferAccepted />;
  const getDeliverImage = () => <UploadImage />;
  let params = new URLSearchParams(window.location.search);
  const formalLanguage = verticalLanguageSettings[params.get("product") || "PKV"];

  return (
    <PageWrap product="">
      {dataAssignment ? (
        <BasePageContent
          title={OFFER_ACCEPTED_SUCCESS.title[formalLanguage]}
          description={OFFER_ACCEPTED_SUCCESS.description[formalLanguage]}
          subDescription={OFFER_ACCEPTED_SUCCESS.subDescription[formalLanguage]}
          buttonName={OFFER_ACCEPTED_SUCCESS.buttonName[formalLanguage]}
          getImage={getFinishImage}
          href={process.env.REACT_APP_MAIN_URL}
        />
      ) : (
        <BasePageContent
          title="The last step!"
          description="Thank you. Please click to continue"
          subDescription={OFFER_ACCEPTED_SUCCESS.subDescription[formalLanguage]}
          getImage={getDeliverImage}
          buttonName="deliver signed document"
          onClick={receive}
        />
      )}
    </PageWrap>
  );
};

export default ReceiveAssignment;
